<template>
  <div class="navbar-bottom">
    <div id="popular-dishes">
      <RecordNotFound v-if="this.$store.getters.discountList.length < 1" />
      <div class="dish" v-if="this.$store.getters.discountList.length > 1">
        <div class="dish-name">Deals List</div>
      </div>
      <div
        v-for="(data, index) in this.$store.getters.discountList"
        :key="index"
        class="meal-container"
      >
        <div class="meal-wrapper" @click="addToCartDiscountItem(data)">
          <div class="meal-name">{{ data.name }}</div>
          <div class="meal-description">{{ data.short_description }}</div>
          <div class="mt-3">
            <span class="meal-price line-through mr-4"
              >{{ data.actual_price.toFixed(2) }} €</span
            >
            <span class="meal-price"
              >{{ data.discount_price.toFixed(2) }} €</span
            >
          </div>
        </div>
        <div class="meal-img cursor-default">
          <img :src="data.coverImage" alt="" />
        </div>
        <div
          style="font-weight: 600; margin-top: 6px; padding-right: 12px"
          @click="showMenuDetail(data)"
        >
          More Info
        </div>
      </div>
    </div>
    <MenuDetail
      :MenuItems="MenuItems"
      @closeModal="closeModal"
      v-if="menuItemDetail"
    />
  </div>
</template>

<script>
import MenuDetail from "../components/Modals/MenuDetail";
import RecordNotFound from "./RecordNotFound";

export default {
  name: "Listing",
  data() {
    return {
      orderList: [],
      MenuItems: [],
      menuItemDetail: false,
    };
  },
  components: {
    MenuDetail,
    RecordNotFound,
  },

  mounted() {},

  methods: {
    increment(index) {
      this.orderList = this.$store.getters.AllorderList;
      this.orderList[index].quantity += 1;
      this.orderList[index].total_price =
        this.orderList[index].price * this.orderList[index].quantity;
      this.$store.commit("newsetAddToCart", this.orderList);
      this.$emit("subTotal");
    },

    closeModal() {
      this.menuItemDetail = false;
    },

    decrement(index) {
      this.orderList = this.$store.getters.AllorderList;
      if (this.orderList[index].quantity == 1) {
        this.orderList[index].quantity = 1;
        this.$store.commit("newsetAddToCart", this.orderList);
        this.$emit("subTotal");
        return false;
      }
      this.orderList[index].quantity -= 1;
      this.orderList[index].total_price -= this.orderList[index].price;
      this.$store.commit("newsetAddToCart", this.orderList);
      this.$emit("subTotal");
    },

    spliceOrder: function (index) {
      this.orderList = this.$store.getters.AllorderList;
      if (index !== -1) {
        this.orderList.splice(index, 1);
      }
      this.$store.commit("newsetAddToCart", this.orderList);
      this.$emit("subTotal");
    },
    addToCartDiscountItem: function (object) {
      this.$emit("addToCartDiscountItem", object);
    },
    showMenuDetail(data) {
      this.menuItemDetail = true;
      this.MenuItems = data;
    },
  },
};
</script>
