<template>
  <div class="custom-container py-5">
    <div class="row">
      <div class="col-12">
        <h1>{{ $t("privacy_statement_h_1") }}</h1>
        <p>
          {{ $t("privacy_statement_p_1") }}<br /><br />{{
            $t("privacy_statement_p_1_1")
          }}
        </p>
        <h2>{{ $t("privacy_statement_h_2") }}</h2>
        <p>
          {{ $t("privacy_statement_p_2") }}
        </p>
        <p>
          1. {{ $t("privacy_statement_point_1") }} <br /><br />{{
            $t("privacy_statement_point_1_p")
          }}
        </p>
        <ul>
          <li>{{ $t("privacy_statement_name") }}</li>
          <li>{{ $t("address_data") }}</li>
          <li>{{ $t("contact_data") }}</li>
          <li>{{ $t("order_and_t_data") }}</li>
          <li>{{ $t("order_payment_data") }}</li>
        </ul>
        <p>
          2. {{ $t("privacy_statement_point_2") }} <br /><br />
          {{ $t("privacy_statement_point_2_p") }}
          <br /><br />{{ $t("privacy_statement_point_2_1_p") }}
        </p>
        <ul>
          <li>{{ $t("privacy_statement_name") }}</li>
          <li>{{ $t("contact_data") }}</li>
          <li>{{ $t("review") }}</li>
        </ul>
        <p>
          3. {{ $t("privacy_statement_point_3") }}

          <br /><br />{{ $t("privacy_statement_point_3_p") }}
        </p>
        <ul>
          <li>{{ $t("privacy_statement_name") }}</li>
          <li>{{ $t("address_data") }} ({{ $t("if_applicable") }})</li>
          <li>{{ $t("contact_data") }}</li>
          <li>{{ $t("order_and_t_data") }}</li>
          <li>{{ $t("payment_data") }} ({{ $t("if_applicable") }})</li>
          <li>{{ $t("comments") }} ({{ $t("if_applicable") }})</li>
        </ul>
        <p>
          4. {{ $t("privacy_statement_point_4") }} <br /><br />{{
            $t("privacy_statement_point_4_p")
          }}
        </p>
        <ul>
          <li>{{ $t("privacy_statement_name") }}</li>
          <li>{{ $t("address_data") }}</li>
          <li>{{ $t("contact_data") }}</li>
        </ul>
        <p>
          5. {{ $t("privacy_statement_point_5") }} <br /><br />{{
            $t("privacy_statement_point_5_p")
          }}
          <br /><br />{{ $t("privacy_statement_point_5_p_2") }}
        </p>
        <ul>
          <li>{{ $t("privacy_statement_name") }}</li>
          <li>{{ $t("address_data") }}</li>
          <li>{{ $t("contact_data") }}</li>
        </ul>
        <p>
          6. {{ $t("privacy_statement_point_6") }}<br /><br />{{
            $t("privacy_statement_point_6_p")
          }}
        </p>
        <ul>
          <li>{{ $t("privacy_statement_name") }}</li>
          <li>{{ $t("address_data") }}</li>
          <li>{{ $t("contact_data") }}</li>
          <li>{{ $t("research_input") }}</li>
        </ul>
        <p>
          7. {{ $t("privacy_statement_point_7") }} <br /><br />{{
            $t("privacy_statement_point_7_p")
          }}
        </p>
        <ul>
          <li>{{ $t("privacy_statement_name") }}</li>
          <li>{{ $t("address_data") }}</li>
          <li>{{ $t("contact_data") }}</li>
          <li>{{ $t("review") }}(s) ({{ $t("optional") }})</li>
          <li>{{ $t("campaign_data") }} ({{ $t("optional") }})</li>
          <li>{{ $t("device_id") }}</li>
          <li>{{ $t("cookie_and_technology") }}</li>
        </ul>
        <p>
          8. {{ $t("privacy_statement_point_8") }} <br /><br />{{
            $t("privacy_statement_point_8_p")
          }}
          <br /><br />{{ $t("privacy_statement_point_8_p_2") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyStatement",
};
</script>

<style>
.custom-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
}

.custom-container h1 {
  font-size: 28px;
  font-weight: 400;
  color: #666;
  margin-bottom: 10px;
}

.custom-container h2 {
  font-size: 21px;
  font-weight: 400;
  color: #666;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  line-height: 32px;
  padding-top: 20px;
}

.custom-container p {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #666;
  margin-bottom: 15px;
}

.custom-container ol,
ul {
  padding-inline-start: 20px;
}

.custom-container ol li,
.custom-container ul li {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #666;
}

.custom-container p strong {
  font-weight: 700;
}

.custom-container .box {
  border: 1px solid #000;
  padding: 15px;
  margin: 15px 0;
}

.letter li {
  list-style-type: lower-latin;
}
</style>
