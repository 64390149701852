<template>
  <div>
    <b-modal
      id="staff-temperature"
      centered
      scrollable
      :title="$t('staff_temperature')"
    >
      <b-table striped bordered hover show-empty empty-text="No record found" :items="staffTemperaturelList" :fields="fields"></b-table>
    </b-modal>
  </div>
</template>

<script>
import { reserveBookingSave } from "../../api/services";
export default {
  name: "StaffTemperature",
  props: {
    staffTemperaturelList: {
      type: Array,
    },
  },
    mounted() {
    console.log("Imran",this.staffTemperaturelList);
  },

  data() {
    return {
      name: null,
      email: null,
      message: null,
      phone: null,
      date: null,
      time: null,
      person: 2,
      buttonloader: false,
      alert: false,
      alertText: "",
      changeClasss: false,
       fields: [

        { key: "name", label: "Name", sortable: true },

        { key: "temperature", label:this.$t("temperature"), sortable: true ,
        formatter: (value) => {
            return value ? value + " °C" : "";
          },
        },
        { key: "designation", label: this.$t("designation"), sortable: true },
      
      ],
    
    };
  },
  methods: {
    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    onSubmit(evt) {
      if (this.date == null) {
        this.validationMessage("Date is required");
        evt.preventDefault();
        return false;
      }
      if (this.time == null) {
        this.validationMessage("Time is required");
        evt.preventDefault();
        return false;
      }
      evt.preventDefault();
      let apiData = JSON.stringify({
        name: this.name,
        email: this.email,
        phone: this.phone,
        date: this.date,
        time: this.time,
        message: this.message,
        restaurant_id: this.$route.params.id,
      });
      this.buttonloader = true;
      reserveBookingSave(apiData).then((responce) => {
        console.log(responce);
        if (responce.code == 200) {
          console.log(responce);
          this.alert = true;
          this.alertText = responce.message;
          this.buttonloader = false;
          this.changeClasss = true;
          this.clearFiled();
        } else {
          this.buttonloader = false;
          this.alert = true;
          this.alertText = responce.message;
          this.changeClasss = false;
        }
      });
    },

    clearFiled() {
      this.name = "";
      this.phone = "";
      this.message = "";
      this.date = "";
      this.time = "";
      this.email = "";
      this.person = 2;
    },
  },
};
</script>

<style scoped>
.b-form-btn-label-control.form-control {
  height: 42px;
  margin-bottom: 0 !important;
}
.submit-btn {
  background-color: #5cb700;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  padding: 8px 18px;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}
.submit-btn:hover {
  background-color: #5cb700;
  color: #fff;
  opacity: 0.8;
}
</style>
