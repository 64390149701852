<template>
  <div class="content">
    <div
      @click="redirectDetail(list.id)"
      class="restaurantCard"
      v-for="(list, index) in listing.data"
      :key="index"
    >
      <div class="topWrapper">
        <div class="coverImg">
          <img :src="list.coverImage" alt="" />
        </div>
        <div class="profileImg">
          <img :src="list.profile_image" alt="" />
        </div>
      </div>
      <div class="bottomWrapper">
        <star-rating
          :read-only="true"
          v-bind:increment="0.5"
          v-bind:max-rating="5"
          :rating="list.rating"
          inactive-color="#666"
          active-color="#5CB700"
          v-bind:star-size="12"
        >
        </star-rating>
        <div class="restaurant-name pt-2">{{ list.name }}</div>
        <div class="kitchen pt-1">
          <div class="pr-1">
            <b>{{ list.opening_time }} - {{ list.closing_time }}</b>
          </div>
        </div>

        <div class="kitchen pt-1">
          {{ categoryWithComma(list.categories) }}
        </div>
        <div class="bottom-wrapper pt-1">
          <div class="mr-3">
            <i class="fas fa-clock pr-1"></i>{{ list.average_delivery_time }}
          </div>
          <div class="mr-3" v-if="list.type == 'Delivery'">
            <i class="fas fa-bicycle pr-1"></i
            >{{ list.addresses.delivery_cost.toFixed(2) }}
          </div>
          <div>
            <i class="fas fa-shopping-bag pr-1"></i>Min
            {{ list.addresses.minimum_order_amount.toFixed(2) }}€
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
export default {
  name: "restaurantCard",
  props: {
    listing: {
      type: Object,
    },
  },
  components: {
    StarRating,
  },
  methods: {
    categoryWithComma(data) {
      let filterCategory = [];
      data.filter((e) => filterCategory.push(e.restaurant_categories.name));
      return filterCategory.join(", ");
    },
    redirectDetail(restaurantId) {
      this.$store.commit("isLoading", true);
      this.$router.push("/restaurant-detail/" + restaurantId);
    },
  },
};
</script>

<style scoped>
.content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1.5rem;
}

.restaurantCard {
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}

.topWrapper {
  position: relative;
}

.profileImg {
  border-radius: 50%;
  border: 1px solid #d7d7d7;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profileImg img {
  width: 90px;
  height: 90px;
  max-width: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.coverImg img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.bottomWrapper {
  padding: 30px 10px 20px;
}

.kitchen {
  margin-bottom: 0;
}

.restaurant-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #0a3847;
  word-break: break-word;
  margin: 0 0 4px;
  white-space: normal;
}
</style>
