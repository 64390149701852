<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      ref="booking"
      id="reserve-booking"
      centered
      scrollable
      :title="$t('reserve_booking')"
    >
      <div
        v-if="alert"
        role="alert"
        aria-live="polite"
        aria-atomic="true"
        :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
      >
        {{ alertText }}
      </div>
      <b-form @submit="onSubmit">
        <h3>{{ $t("book_table") }}</h3>
        <div class="row pb-3">
          <div class="col-md-6 col-12 pt-3">
            <b-form-datepicker
              :min="min"
              id="example-datepicker"
              v-model="date"
              @input="changeDate()"
              class="mb-2 form-control date"
            ></b-form-datepicker>
          </div>
          <div class="col-md-6 col-12 pt-3">
            <vue-timepicker
              id="timepicker12"
              @change="changeHandler"
              :hour-range="maxHours"
              :minute-range="maxMinutes"
              format="HH:mm"
              v-model="time"
            ></vue-timepicker>
          </div>

          <div class="col-md-6 col-12 pt-3">
            <b-form-select class="dropdown" v-model="person">
              <option v-for="i in 49" :key="i" :value="i + 1">
                {{ i + 1 }}
              </option>
            </b-form-select>
          </div>
        </div>
        <h3>{{ $t("contact_detail") }}</h3>
        <div class="row pb-3">
          <div class="col-md-6 col-12 pt-3">
            <b-input
              type="text"
              v-model="name"
              required
              class="form-control"
              :placeholder="$t('form_name') + ' *'"
            />
          </div>
          <div class="col-md-6 col-12 pt-3">
            <b-input
              type="email"
              required
              :placeholder="$t('form_email') + ' *'"
              v-model="email"
              class="form-control"
            />
          </div>
          <div class="col-md-6 col-12 pt-3">
            <b-input
              type="number"
              v-model="phone"
              required
              class="form-control inputPrice"
              :placeholder="$t('form_phone') + ' *'"
            />
          </div>
        </div>

        <div class="row col-12 pt-3">
          <b-form-textarea
            id="textarea"
            v-model="message"
            :placeholder="$t('message')"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>

        <div class="row col-12 pt-3">
          <b-form-checkbox required v-model="accept_term" rows="3"
            ><p style="font-size: 13px">
              {{ $t("booking_checkbox") }}
            </p></b-form-checkbox
          >
        </div>

        <div class="col-12 text-center">
          <button class="submit-btn mb-3">
            <i
              style="color: rgb(123 217 182)"
              class="fa fa-spinner fa-spin"
              v-if="buttonloader"
            ></i
            >{{ $t("request_booking") }}
          </button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { reserveBookingSave } from "../../api/services";
import moment from "moment";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  name: "ReserveBooking",
  components: {
    VueTimepicker,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    console.log(moment(now).format("YYYY-MM-DD"));
    // 15th two months prior
    const minDate = new Date(today);
    return {
      min: minDate,
      // time: {
      //   hh: moment(now).add(1, "hours").format("hh"),
      //   mm: moment(now).format("mm"),
      //   a: moment(now).add(1, "hours").format("a"),
      // },
      time: null,

      maxHours: [[moment().add(1, "hours").format("HH"), "23"]],
      maxMinutes: [[0, 60]],
      name: null,
      email: this.$store.state.loginUserData.email,
      accept_term: 0,
      message: null,
      phone: null,
      date: moment(now).format("YYYY-MM-DD"),
      id: "",
      currentData: moment(now).format("YYYY-MM-DD"),
      openingHours: this.$store.getters.restaurantDetails.opening_time,
      closingHours: this.$store.getters.restaurantDetails.closing_time,
      person: 2,
      buttonloader: false,
      readyforbooking: false,
      restaurantClose: false,
      alert: false,
      alertText: "",
      changeClasss: false,
    };
  },

  mounted() {
    let openingHours =
      this.$store.getters.restaurantDetails.opening_time.split(":")[0];
    let closingHours =
      this.$store.getters.restaurantDetails.closing_time.split(":")[0];
    let openingMinutes =
      this.$store.getters.restaurantDetails.opening_time.split(":")[1];
    let closingMinutes =
      this.$store.getters.restaurantDetails.closing_time.split(":")[1];
    this.maxHours = [[openingHours, closingHours]];
    if (openingHours > closingHours && 23 > closingHours) {
      this.maxHours = [
        [openingHours, 23],
        [0, closingHours],
      ];
    }

    this.maxMinutes = [[openingMinutes, closingMinutes]];
    console.log("this.maxHours", this.maxHours);
  },

  methods: {
    changeDate() {
      this.calenderValidations();
    },
    dateFormat(object) {
      return object.HH + ":" + object.mm;
    },

    changeHandler(eventData) {
      this.time = eventData.data;
      if (
        this.time.HH &&
        this.time.HH !=
          this.$store.getters.restaurantDetails.closing_time.split(":")[0]
      ) {
        this.maxMinutes = [[0, 60]];
      } else {
        this.maxMinutes = [
          [0, this.$store.getters.restaurantDetails.closing_time.split(":")[1]],
        ];
      }
      if (this.time.HH && this.time.mm) this.timeValidationForOrder();
    },
    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    calenderValidations() {
      if (this.time.HH && this.time.mm) this.timeValidationForOrder();
    },
    timeValidationForOrder() {
      this.readyforbooking = false;
      var currentDate = moment(new Date()).format("YYYY-MM-DD");
      this.alert = false;
      let currentTime = moment().toDate();
      let closingtime = moment(this.date + " " + this.closingHours).toDate();

      if (this.date == currentDate && currentTime > closingtime) {
        this.validationMessage("Restaurant is closed at the selected time ");
        return false;
      }
      if (this.date == currentDate) {
        let todayDate = moment(new Date()).add("hour", 1).toDate();
        let time = moment(
          this.date + " " + this.dateFormat(this.time)
        ).toDate();

        if (time < todayDate) {
          this.validationMessage(
            "For today, please select time after " +
              moment().add("hour", 1).format("HH:mm")
          );
          return false;
        }
      }

      this.readyforbooking = true;
    },
    onSubmit(evt) {
      if (this.date == null) {
        this.validationMessage("Date is required");
        evt.preventDefault();
        return false;
      }
      if (this.time == null) {
        this.validationMessage("Time is required");
        evt.preventDefault();
        return false;
      }

      if (this.accept_term == 0) {
        this.validationMessage(
          "Please agree to guten appetite Local Terms of Use and confirm the Privacy Policy."
        );
        evt.preventDefault();
        return false;
      }
      if (!this.readyforbooking) {
        evt.preventDefault();
        return false;
      }
      evt.preventDefault();
      let apiData = JSON.stringify({
        name: this.name,
        email: this.email,
        phone: this.phone,
        person: this.person,
        date: this.date,
        time: this.dateFormat(this.time),
        id: this.id,
        message: this.message,
        progress_status: "Pending",
        restaurant_id: this.$route.params.id,
      });
      this.buttonloader = true;
      reserveBookingSave(apiData).then((responce) => {
        if (responce.code == 200) {
          this.alert = true;
          this.alertText = responce.message;
          this.buttonloader = false;
          this.changeClasss = true;
          this.clearFiled();
          setTimeout(() => this.$refs["booking"].hide(), 500);
          this.alert = false;
        } else {
          this.buttonloader = false;
          this.alert = true;
          this.alertText = responce.message;
          this.changeClasss = false;
        }
      });
    },

    clearFiled() {
      this.name = "";
      this.phone = "";
      this.message = "";
      this.date = moment(new Date()).format("YYYY-MM-DD");
      this.time = null;
      this.email = this.$store.state.loginUserData.email;
      this.person = 2;
      this.accept_term = 0;
    },
  },
};
</script>

<style scoped>
.submit-btn {
  background-color: #5cb700;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  padding: 8px 18px;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}
.submit-btn:hover {
  background-color: #5cb700;
  color: #fff;
  opacity: 0.8;
}
</style>
