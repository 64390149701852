<template>
  <table class="table table-bordered">
    <thead class="thead">
      <tr>
        <th>Item Name</th>
        <th>Quantity</th>
        <th>Unit Price</th>
        <th>Total price</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(meunItem, key) in this.meunItems" :key="key">
        <td>
          {{ meunItem.name }}
        </td>
        <td>
          {{ meunItem.quantity }}
        </td>
        <td>
          {{ meunItem.price }}
        </td>
        <td>
          {{ meunItem.total_price }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  components: {},
  props: {
    meunItems: {},
  },
  data() {
    return {
      checkedNames: [],
    };
  },

  mounted() {
    console.log(this.meunItems);
  },

  methods: {},
};
</script>
