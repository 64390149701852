<template>
   <div class="custom-container py-5">
      <div class="row">
         <div class="col-12">
            <h2>{{ $t("cookie_statement_h1") }}</h2>
            <p>{{ $t("cookie_statement_desc_1") }}
               <br><br>{{ $t("cookie_statement_desc_2") }}
            </p>
            <h2>{{ $t("cookie_statement_h2") }}</h2>
            <div class="row">
               <div class="col-md-6 col-12">
                  <div class="box">
                     <p><strong>{{ $t("cookie_statement_h2_box_1_h") }}</strong><br> {{ $t("cookie_statement_h2_box_1_desc") }}</p>
                  </div>
               </div>
               <div class="col-md-6 col-12">
                  <div class="box">
                     <p><strong>{{ $t("cookie_statement_h2_box_2_h") }}</strong><br> {{ $t("cookie_statement_h2_box_2_desc") }}</p>
                  </div>
               </div>
            </div>
            <h2>{{ $t("cookie_statement_section_3_h3") }}</h2>
            <p>{{ $t("cookie_statement_section_3_desc1") }}
               <br><br>1. {{ $t("cookie_statement_section_3_point_1") }}<br><br>{{ $t("cookie_statement_section_3_point_1_desc") }}
               <br><br>2. {{ $t("cookie_statement_section_3_point_2") }} <br><br>{{ $t("cookie_statement_section_3_point_2_desc") }}
               <br><br>3. {{ $t("cookie_statement_section_3_point_3") }} <br><br> {{ $t("cookie_statement_section_3_point_3_desc") }}
               <br><br>4. {{ $t("cookie_statement_section_3_point_4") }} <br><br>{{ $t("cookie_statement_section_3_point_4_desc") }}
            </p>
            <h2>{{ $t("cookie_statement_section_4_heading") }}</h2>
            <p>{{ $t("cookie_statement_section_4_desc") }} <br><br>
               1. {{ $t("cookie_statement_section_4_point_1") }} <br><br>{{ $t("cookie_statement_section_4_point_1_desc") }}
               <br><br>2. {{ $t("cookie_statement_section_4_point_2") }} <br><br>{{ $t("cookie_statement_section_4_point_2_desc") }}
               <br><br>3. {{ $t("cookie_statement_section_4_point_3") }}
               <br><br>{{ $t("cookie_statement_section_4_point_3_desc") }}
            </p>
            <h2>{{ $t("cookie_statement_section_5_heading") }}</h2>
            <p>1. {{ $t("cookie_statement_section_5_point_1") }} <br><br>{{ $t("cookie_statement_section_5_point_1_desc") }}
               <br><br>2. {{ $t("cookie_statement_section_5_point_2") }}
               <br><br>{{ $t("cookie_statement_section_5_point_2_desc") }}
            </p>
            <h2>{{ $t("cookie_statement_section_6_heading") }}</h2>
            <p>1. {{ $t("cookie_statement_section_6_point_1") }}
               <br><br>{{ $t("cookie_statement_section_6_point_1_desc") }} <br><br>
               <ul>
                  <li>{{ $t("chrome") }}</li>
                  <li>{{ $t("firefox") }}</li>
                  <li>{{ $t("edge") }}</li>
                  <li>{{ $t("safari") }}</li>
                  
               </ul>
               2. {{ $t("cookie_statement_section_6_point_2") }} <br><br>{{ $t("cookie_statement_section_6_point_2_desc") }}
               <br><br>3. {{ $t("cookie_statement_section_6_point_3") }}
               <br><br> {{ $t("cookie_statement_section_6_point_3_desc") }}
               <br><br>{{ $t("modified_cookie_statment") }}
            </p>
         </div>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'CookieStatement'
   }
</script>