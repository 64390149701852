<template>
  <div>
    <div v-if="listing.data.length > 0" class="title"></div>
    <div v-if="listing.data.length == 0" class="title text-center">
      {{ $t("record_not_found") }}
    </div>

    <div
      class="restaurant-listing"
      v-for="(list, index) in listing.data"
      :key="index"
    >
      <div class="details-wrapper">
        <div class="bottom-wrapper">
          <span class="col-md-2">{{ $t("issued") }} {{ $t("date") }}</span>
          <span>{{ list.start_date }}</span>
        </div>
        <div class="bottom-wrapper">
          <span class="col-md-2">{{ $t("expire") }} {{ $t("date") }}</span>
          <span>{{ list.expire_date }}</span>
        </div>
        <div class="bottom-wrapper">
          <span class="col-md-2">{{ $t("type") }}</span>
          <span>{{ list.voucher.type }}</span>
        </div>

        <div
          class="bottom-wrapper"
          v-if="list.voucher.type == 'Order discount'"
        >
          <span class="col-md-2"
            >{{ $t("voucher") }} {{ $t("v_discount") }} %</span
          >
          <span>{{ list.voucher_discount.toFixed(2) + " €" }} </span>
        </div>

        <div class="bottom-wrapper">
          <span class="col-md-2">{{ $t("voucher") }} {{ $t("amount") }}</span>
          <span>{{ list.voucher.voucher_amount }}</span>
          <span class="ml-2">{{
            list.voucher.type == "Order discount" ? "%" : "€"
          }}</span>
        </div>

        <div class="bottom-wrapper" v-if="list.voucher.type == 'Referral code'">
          <span class="col-md-2">{{ $t("referr_to") }}</span>
          <span>{{ list.referralTo ? list.referralTo.username : "-" }}</span>
        </div>
      </div>
    </div>

    <pagination
      :data="listing.pagination"
      :limit="4"
      align="center"
      @pagination-change-page="voucherPagination"
    >
    </pagination>
  </div>
</template>

<script>
export default {
  name: "Favourites",
  props: {
    listing: {
      type: Object,
    },
  },
  data() {
    return {
      order: {},
      showModal: false,
      isShowModel: false,
    };
  },
  methods: {
    reserveInfo(key) {
      this.listing = this.order;
      console.log(key);
    },
    voucherPagination(page = 1) {
      const data = {
        per_page: "10",
        page: page,
      };
      this.$emit("voucherPagination", data);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.logo-wrapper {
  flex-direction: column;
}

/* .bottom-wrapper span:nth-child(2) {
  padding-left: 0px;
} */

.title {
  font-size: 20px;
  color: #666;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}

.desc {
  font-size: 13px;
  font-weight: 400;
  color: #666;
  line-height: 20px;
}

.label {
  font-size: 15px;
  color: #666;
  padding-top: 20px;
  padding-bottom: 5px;
}

.mainLabel {
  font-size: 11px;
  color: #666;
}

textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  border-color: #dedede;
  padding: 5px;
}
</style>
