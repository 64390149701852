<template>
  <div>
    <b-modal
      id="about-restaurant"
      centered
      scrollable
      :title="$t('about_restaurant')"
    >
      <b-tabs pills card>
        <b-tab :title="$t('review')" class="reviewTab">
          <div class="record-not-found" v-if="reviewsList.data.length == 0">
            {{ $t("record_not_found") }}
          </div>
          <div class="px-3">
            <div
              class="reviewBox"
              v-for="(item, index) in reviewsList.data"
              :key="index"
            >
              <div class="name">{{ item.customer.username }}</div>
              <div class="text">
                {{ moment.utc(item.date).local().format("dddd, MMMM Do YYYY") }}
              </div>
              <div
                class="d-flex justify-content-between align-items-center pt-2"
              >
                <div class="label">{{ $t("food_quality_rating") }}</div>
                <star-rating
                  read-only="true"
                  v-bind:max-rating="5"
                  v-bind:increment="0.5"
                  :rating="parseFloat(item.food_quality_rating)"
                  inactive-color="#fff"
                  active-color="#5CB700"
                  v-bind:star-size="17"
                >
                </star-rating>
              </div>
              <div
                class="d-flex justify-content-between align-items-center pt-2"
              >
                <div class="label">{{ $t("delivery_quality_rating") }}</div>
                <star-rating
                  read-only="true"
                  v-bind:max-rating="5"
                  v-bind:increment="0.5"
                  :rating="parseFloat(item.delivery_quality_rating)"
                  inactive-color="#fff"
                  active-color="#5CB700"
                  v-bind:star-size="17"
                >
                </star-rating>
              </div>
              <div class="text pt-3">
                {{ item.review }}
              </div>
            </div>

            <pagination
              :data="reviewsList.pagination"
              @pagination-change-page="paginationResult"
              :limit="4"
              align="center"
            >
            </pagination>
          </div>
        </b-tab>
        <b-tab title="Info" active>
          <div class="label pb-2">
            <i class="fa fa-clock-o pr-2"></i>{{ $t("opening_closing_times") }}
          </div>
          <div class="boxWrapper">
            <div class="d-flex justify-content-between">
              <span
                >{{ restaurantDetail.opening_time }} -
                {{ restaurantDetail.closing_time }}</span
              >
            </div>
          </div>
          <div class="label pb-2">
            <i class="fa fa-bicycle pr-2"></i>{{ $t("delivery_cost") }}
          </div>
          <div class="boxWrapper">
            <div class="d-flex justify-content-between">
              <span>{{ $t("minimum_order_amount") }}</span>
              <span
                >{{
                  restaurantDetail.addresses
                    ? restaurantDetail.addresses.minimum_order_amount.toFixed(2)
                    : ""
                }}
                €</span
              >
            </div>
            <div
              class="d-flex justify-content-between"
              v-if="restaurantDetail.type == 'Delivery'"
            >
              <span>{{ $t("delivery_cost") }}</span>
              <span
                >{{
                  restaurantDetail.addresses
                    ? restaurantDetail.addresses.delivery_cost.toFixed(2)
                    : ""
                }}
                €</span
              >
            </div>
          </div>

          <div class="label pb-2">
            <i class="fas fa-map-marker-alt"></i> {{ $t("address_footer") }}
          </div>
          <div class="boxWrapper">
            {{ restaurantDetail.location }}
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import Vue from "vue";
import moment from "moment";
Vue.prototype.moment = moment;
export default {
  name: "AboutRestaurant",
  props: {
    restaurantDetail: {
      type: Object,
    },
    reviewsList: {
      type: Object,
    },
  },
  data() {
    return {};
  },

  methods: {
    paginationResult: function (page) {
      this.$emit("forPagination", page);
    },
  },

  mounted() {
    console.log(this.reviewsList.data);
  },

  components: {
    StarRating,
  },
};
</script>

<style scoped>
.label {
  font-size: 18px;
  color: #0a3847;
  font-weight: 600;
}
.record-not-found {
  font-size: 25px;
  margin-bottom: 10px;
  min-height: 30vh;
  font-weight: 700;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxWrapper {
  font-weight: 400;
  color: #666;
  line-height: 1.71;
  letter-spacing: normal;
  background-color: #f8f5f2;
  padding: 16px 24px;
  font-size: 14px;
  margin-bottom: 10px;
}

.reviews {
  background-color: #0a3847;
  margin: 0;
  padding: 24px 44px;
  color: #fff;
}

.reviewTab {
  padding: 0;
}

.ratingNum {
  font-weight: 600;
  font-size: 36px;
  width: 72px;
  height: 48px;
  border-right: 1px solid #666;
}

.total {
  font-size: 12px;
  font-weight: 300;
  color: #f8f5f2;
  padding-top: 5px;
}

.score {
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 10px;
}

.reviewBox {
  background-color: #f8f5f2;
  padding: 16px 24px;
  margin: 16px 0;
}

.name {
  color: #5cb700;
}

.reviewBox .label {
  font-weight: 600;
  font-size: 14px;
  color: #0a3847;
}

.reviewBox .text {
  font-weight: 400;
  display: block;
  color: #666;
  font-size: 13px;
  line-height: 22px;
  text-align: left;
  word-wrap: break-word;
}

@media only screen and (max-width: 576px) {
  .reviewBox {
    padding: 16px 10px;
  }
}
</style>
