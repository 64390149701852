import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../pages/Main";
import Home from "../components/Home";
import Listing from "../components/Listing";
import RestaurantDetail from "../components/RestaurantDetail";
import Checkout from "../components/Checkout";
import MyAccount from "../components/MyAccount";
import Favourites from "../components/Favourites";
import RecommendRestaurant from "../components/RecommendRestaurant";
import SignupRestaurant from "../components/SignupRestaurant";
import Alergies from "../components/Alergies";
import TermsOfUse from "../components/TermsOfUse";
import PrivacyStatement from "../components/PrivacyStatement";
import Colophan from "../components/Colophan";
import CookieStatement from "../components/CookieStatement";
import Facebook from "../components/Facebook";
import ResetPassword from "../components/ResetPassword";
import PayPal from "../components/PayPal";
import store from "../store/index";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "",
      component: Main,
      children: [
        {
          path: "",
          name: "Home",
          component: Home,
        },
        {
          path: "/payment",
          name: "Payment",
          component: PayPal,
        },
        {
          path: "/delivery",
          name: "Listing",
          component: Listing,
        },
        {
          path: "/facebook",
          name: "Facebook",
          component: Facebook,
        },
        {
          path: "/restaurant-detail/:id",
          name: "Restaurant-Detail",
          component: RestaurantDetail,
        },
        {
          path: "/checkout",
          name: "Checkout",
          component: Checkout,
        },
        {
          path: "/my-account",
          name: "MyAccount",
          component: MyAccount,
          beforeEnter: (to, from, next) => {
            if (!store.state.isAuthenticated) {
              // not logged in so redirect to login page with the return url
              return next({ path: "/" });
            } else {
              next();
            }
          },
        },
        {
          path: "/reset-password/:code",
          name: "ResetPassword",
          component: ResetPassword,
        },
        {
          path: "/favourites",
          name: "Favourites",
          component: Favourites,
        },
        {
          path: "/recommend",
          name: "RecommendRestaurant",
          component: RecommendRestaurant,
        },
        {
          path: "/signup",
          name: "SignupRestaurant",
          component: SignupRestaurant,
        },
        {
          path: "/terms-of-use",
          name: "TermsOfUse",
          component: TermsOfUse,
        },
        {
          path: "/privacy-statement",
          name: "PrivacyStatement",
          component: PrivacyStatement,
        },
        {
          path: "/colophan",
          name: "Colophan",
          component: Colophan,
        },
        {
          path: "/alergies",
          name: "Alergies",
          component: Alergies,
        },
        {
          path: "/cookie-statement",
          name: "CookieStatement",
          component: CookieStatement,
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.commit("setsearchText", "");
  if (to.name == "Listing") {
    store.commit("setZipCode", true);
  } else {
    store.commit("setZipCode", false);
  }
  next();
});

export default router;
