<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="order-detail"
      class="mx-auto"
      centered
      ref="order-detail"
      title="Order Detail"
    >
      <div class="row">
        <div class="col-md-6">
          <b>{{ $t("customer_heading") }} {{ $t("form_name") }}</b>
        </div>
        <div class="col-md-6">
          <span>{{ this.order.customerDetail.username }}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <b>{{ $t("restaurant") }} {{ $t("form_name") }}</b>
        </div>
        <div class="col-md-6">
          <span>{{ this.order.restaurant.name }}</span>
        </div>
      </div>
      <br />
      <div v-if="this.voucher">
        <b class="card-title">{{ $t("voucher") }}</b>

        <div class="row mt-2">
          <Voucher :voucher="voucher" />
        </div>
      </div>
      <div v-if="this.meunItems.length > 0">
        <b class="card-title">{{ $t("menu_item") }}</b>

        <div class="row mt-2">
          <MenuItems :meunItems="meunItems" />
        </div>
      </div>
      <div v-if="this.discountedItems.length > 0">
        <b class="card-title">{{ $t("discounted_list") }}</b>
        <div class="row mt-2">
          <DiscountItemList :discountedItems="discountedItems" />
        </div>
      </div>

      <div class="row mt-2" v-if="this.order.orderDeliveryAddress">
        <div class="col-md-6">
          <b>{{ $t("delivery_address") }}</b>
        </div>
        <div class="col-md-6">
          <span>{{ bindAddress(this.order.orderDeliveryAddress) }}</span>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-6">
          <b>{{ $t("actual") }} {{ $t("price") }}</b>
        </div>
        <div class="col-md-6">
          <span>{{
            (this.order.totalprice + this.order.voucher_discount).toFixed(2) +
            " €"
          }}</span>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-6"><b>Voucher Discount</b></div>
        <div class="col-md-6">
          <span>{{ this.order.voucher_discount.toFixed(2) + " €" }}</span>
        </div>
      </div>

      <div class="row mt-2" v-if="this.order.ordertype != 'Pickup'">
        <div class="col-md-6">
          <b>{{ $t("delivery_charges") }}</b>
        </div>
        <div class="col-md-6">
          <span>{{
            this.order.orderDeliveryAddress.delivery_cost.toFixed(2) + " €"
          }}</span>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-6">
          <b>{{ $t("total") }} {{ $t("price") }}</b>
        </div>
        <div class="col-md-6">
          <span>{{ this.totalPrice(this.order) + " €" }}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <b>{{ $t("type") }}</b>
        </div>
        <div class="col-md-6">
          <span>{{ this.order.ordertype }}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <b>{{ $t("payment") }} Status</b>
        </div>
        <div class="col-md-6">
          <span>{{ this.order.payment_status ? "Done" : "Pending" }}</span>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end pt-4 mb-2">
        <button class="btn btn-danger btn-sm ml-2" @click="hideModal()">
          {{ $t("close") }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Voucher from "../Voucher";
import MenuItems from "../MenuItems";
import DiscountItemList from "../DiscountItemList";

export default {
  name: "Favourites",
  data() {
    return {
      voucher: {},
      meunItems: {},
      discountedItems: {},
    };
  },
  props: {
    order: {
      type: Object,
    },
    orderShow: {
      type: Boolean,
      default: false,
    },
  },
  components: { Voucher, MenuItems, DiscountItemList },
  methods: {
    hideModal() {
      this.showModel = false;
      this.$refs["order-detail"].hide();
      this.$emit("closeModal", false, "order");
    },

    totalPrice(object) {
      return object.ordertype == "Delivery"
        ? (
            object.totalprice + object.orderDeliveryAddress.delivery_cost
          ).toFixed(2)
        : object.totalprice.toFixed(2);
    },
    bindAddress(object) {
      let house_no = object.house_no ? object.house_no + ", " : "";
      let floor = object.floor ? object.floor + ", " : "";
      let street = object.street ? object.street + ", " : "";
      let postcode = object.postcode ? object.postcode + ", " : "";
      let city = object.city ? object.city : "";
      let completeAddress = street + house_no + floor + postcode + city;
      return completeAddress;
    },
  },
  mounted() {
    this.voucher = this.order.voucher;
    this.meunItems = this.order.customerOrdersDetail;
    this.discountedItems = this.order.discounteditems;
    this.$refs["order-detail"].show();
    this.showModel = true;
  },
};
</script>

<style scoped>
.label {
  font-size: 15px;
  color: #666;
  padding-top: 20px;
  padding-bottom: 5px;
}

textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  border-color: #dedede;
  padding: 5px;
}
</style>
