import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router/routes";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-search-select/dist/VueSearchSelect.css";
import VScrollToTop from "v-scroll-to-top";
import { i18n } from "./plugins/i18n";

import VueAxios from "vue-axios";
import axios from "axios";
import Vuex from "vuex";
import store from "./store/index";

import "font-awesome/css/font-awesome.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
// import { func } from "./global/helper.js";
// Vue.prototype.$func = func;
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueScrollactive from "vue-scrollactive";
let path = "https://gutenapp.crunchyapps.com/api/";
Vue.prototype.$path = path;
import * as VueGoogleMaps from "vue2-google-maps";
import CxltToastr from "cxlt-vue2-toastr";
import "cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css";
Vue.use(CxltToastr);

Vue.use(VueScrollactive);
Vue.use(VueAxios, axios);
Vue.use(VScrollToTop);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAhTq2jqNWgayYdJhDZ2XYUHHmJ0SD_aKU",
    libraries: "places",
  },
});
Vue.component("pagination", require("laravel-vue-pagination"));

Vue.config.productionTip = false;

new Vue({
  axios,
  i18n,
  VueAxios,
  VueRouter,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
