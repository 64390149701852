<template>
    <div>
        <b-modal ref="hide_model" id="create-account" :no-close-on-backdrop="true" size="lg" centered scrollable>
            <template #modal-header>
                <h5 class="modal-title">{{$t('register')}}</h5>
                <button @click="hideModal()" type="button" class="close">
                    ×
                </button>
            </template>
            <GoogleLogin class="google-btn" :params="params" :onSuccess="onGmailSuccess" :onFailure="onFailure"><i
                    class="fab fa-google pr-3"></i> {{$t('sign_up_with_google')}}</GoogleLogin>

            <facebook-login :logoutLabel="$t('sign_in_with_facebook')" :loginLabel="$t('sign_up_with_facebook')" class="google-btn facebook-btn" @sdk-loaded="sdkLoaded"
                app-id="756691688970265" @login="getUserData" @logout="onLogout">
            </facebook-login>
            <div class="line">
                <h2><span>or</span></h2>
            </div>
            <p v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul class="ErrorClass">
                    <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
            </p>
            <div v-if="alert" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">
                {{ alertText }}
            </div>
            <b-form  v-on:submit.prevent="onSubmit" ref="form_id">

                <b-form-group id="input-group-1" :label="$t('form_name')+ ' *'" >
                    <b-form-input type="text" required="" v-model="username"></b-form-input>
                </b-form-group>

                <b-form-group :label="$t('form_email') + ' *'">
                    <b-form-input type="email" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" required="" v-model="email"></b-form-input>
                </b-form-group>

                <b-form-group :label="$t('form_phone')+ ' *'">
                    <b-form-input type="number" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" required="" v-model="phone_number"></b-form-input>
                </b-form-group>

                <b-form-group :label="$t('form_password')+ ' *'">
                    <b-form-input type="password" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" required="" v-model="password" ></b-form-input>
                </b-form-group>
                
                <b-form-group :label="$t('form_c_password')+ ' *'">
                    <b-form-input  type="password" required="" v-model="confirmPassword">
                    </b-form-input>
                </b-form-group>

                <b-form-group :label="$t('referral_code')">
                    <b-form-input type="text" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"  v-model="referral_code"></b-form-input>
                </b-form-group>

                <button class="google-btn facebook-btn"> <i style="color:rgb(123 217 182)"
                        class="fa fa-spinner fa-spin " v-if="buttonloader"></i>{{$t('send_button')}}</button>
            </b-form>

        </b-modal>
    </div>
</template>

<script>
    import facebookLogin from 'facebook-login-vuejs';
    import GoogleLogin from 'vue-google-login';
    import axios from "axios";
    // import Vue from "vue";
    // import DisableAutocomplete from "vue-disable-autocomplete";
    // Vue.use(DisableAutocomplete);

    import {
        socialSignUp
    } from "../../api/services";
    import {
        register
    } from "../../api/services"


    export default {
        data() {
            return {
                params: {
                    client_id: "1062773302676-9jeoib8jsjnugtlcp65h457epkbhhvt0.apps.googleusercontent.com"
                },
                buttonloader: false,
                alert: false,
                alertText: '',
                errors: [],
                isError: false,
                phone_number: "",
                username: "",
                email: "",
                password: "",
                referral_code: "",
                confirmPassword: '',
                FB: undefined
            };
        },

        components: {
            GoogleLogin,
            facebookLogin
        },

        mounted() {
            this.disableAutoComplete();
        },

        methods: {
            sdkLoaded(payload) {
                this.isConnected = payload.isConnected
                this.FB = payload.FB
                if (this.isConnected) this.getUserData()
            },

            hideModal() {
                this.alert=false
                this.alertText=""
                this.$refs['hide_model'].hide()
            },

            disableAutoComplete() {
                let elements = document.querySelectorAll('[autocomplete="off"]');
                console.log("==>", elements);
                if (!elements) {
                    return
                }

                elements.forEach(element => {
                    setTimeout(() => {
                        element.removeAttribute("readonly")
                    }, 2000)
                })
            },

            getUserData(data) {
                let token = data.response.authResponse.accessToken;
                axios.get(`https://graph.facebook.com/v8.0/me?access_token=${token}`)
                    .then(response => {
                        console.log(response);
                        let apiData = JSON.stringify({
                            token: response.data.id,
                            provider_type: 'facebook',
                            platform: "web",
                            email: (response.data.email) ? response.data.email : "",
                            username: (response.data.name) ? response.data.name : "",
                        });
                        socialSignUp(apiData).then((responce) => {
                            if (responce.code == 200) {
                                this.successSignUp(responce);
                            } else {
                                this.alertText = responce.message;
                                this.alert = true;
                                this.buttonloader = false;
                            }
                        });
                    });

            },

            onLogout() {
                console.log("this.isConnected = false;")
                this.isConnected = false;
            },
            successSignUp(responce) {
                this.$store.commit("updateLoginUser", responce.data);
                localStorage.setItem("isAuthenticated", true);
                this.$store.commit("isLogin", true);
                localStorage.setItem("currentUser", JSON.stringify(responce.data));
                localStorage.setItem("token", JSON.stringify(responce.data.token));
                this.$bvModal.hide('login');
                this.$refs['hide_model'].hide();
            },
            onGmailSuccess(data) {
                let apiData = JSON.stringify({
                    username: data.getBasicProfile().getName(),
                    email: data.getBasicProfile().getEmail(),
                    provider_type: 'google',
                    token: data.getBasicProfile().getId(),
                    platform: "web"
                });
                socialSignUp(apiData).then((responce) => {
                    if (responce.code == 200) {
                        this.successSignUp(responce);
                        

                    } else {
                        this.alertText = responce.message;
                        this.alert = true;
                        this.buttonloader = false;
                    }

                });
            },
            onFailure(data) {
                console.log("===>", data)
            },

            clerField(){
             this.email="";
             this.username=""
             this.password="";
             this.phone_number="";
             this.referral_code="";
             this.confirmPassword="";
            },


            onSubmit: async function () {
                try {
                    this.buttonloader = true;
                    this.alert = false;
                    this.errors = [];
                    this.isError = false;

                    if (this.password !== this.confirmPassword) {
                        this.errors.push('Password Should be matched');
                        this.isError = true;
                        this.buttonloader = false;
                        return false;
                    }

                    let apiData = JSON.stringify({
                        username: this.username,
                        email: this.email,
                        phone_number: this.phone_number,
                        password: this.password,
                        referral_code: this.referral_code,
                        platform: "web"
                    });


                    register(apiData).then((responce) => {
                        this.buttonloader = false;
                        if (responce.code == 200) {
                            this.successSignUp(responce);
                            this.clerField();

                        } else {
                            this.alertText = responce.message;
                            this.alert = true;
                            this.buttonloader = false;

                        }

                    });


                } catch (err) {
                    if (!err.response) {
                        alert("Please Check You Internet Connection");
                        this.buttonloader = false;
                    }
                }


            }
        }

    };
</script>