<template>
  <table class="table table-bordered">
    <thead class="thead">
      <tr>
        <th>Id</th>
        <th>Referral To</th>
        <th>Referral By</th>
        <th>Type</th>
        <th>Expire duration</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          {{ this.voucher.voucher.unique_code }}
        </td>
        <td>
          {{ this.voucher.referralTo.username }}
        </td>
        <td>
          {{ this.voucher.referralBy.username }}
        </td>
        <td>
          {{ this.voucher.voucher.type }}
        </td>
        <td>
          {{ this.voucher.expire_duration }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  components: {},
  props: {
    voucher: {},
  },
  data() {
    return {
      checkedNames: [],
    };
  },

  mounted() {},

  methods: {},
};
</script>
