import store from "../store/index";
// var baseUrl = "https://backend.gutenappetite.de/api/";
// var baseUrl = "https://guten-backend.tajtrudering.de/api/";
var baseUrl = "https://gutenappetite-backend.crunchyapps.com/api/";
const apiUrl = baseUrl;
export { apiUrl };

export async function checkLatLng(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    method: "POST",
    body: JSON.stringify({
      addresses: JSON.stringify({ postcode: object.postcode }),
      restaurant_slug: store.getters.restaurantDetails.slug,
    }),
  };
  return await fetch(
    baseUrl + "customer/checkLatLngExist",
    requestOptions
  ).then((data) => data.json());
}

export async function favouriteRestaurants(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "customer/orderPlace", requestOptions).then(
    (data) => data.json()
  );
}

export async function orderPlace(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "customer/orderPlace", requestOptions).then(
    (data) => data.json()
  );
}

export async function register(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "customer/register", requestOptions).then(
    (data) => data.json()
  );
}

export async function socialSignUp(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "customer/socialSignupV1", requestOptions).then(
    (data) => data.json()
  );
}

export async function socialLogin(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "customer/socialLoginV1", requestOptions).then(
    (data) => data.json()
  );
}

export async function refreshLogin() {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: "",
  };
  return await fetch(baseUrl + "customer/refreshLogin", requestOptions).then(
    (data) => data.json()
  );
}

export async function orderList(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    method: "POST",
    body: object,
  };
  return await fetch(
    baseUrl + "customer/customer-order-list",
    requestOptions
  ).then((data) => data.json());
}

export async function favRestaurantList(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: object,
  };
  return await fetch(
    baseUrl + "customer/fav-restaurant-list",
    requestOptions
  ).then((data) => data.json());
}

export async function orderListing(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(
    baseUrl + "customer/customer-order-list",
    requestOptions
  ).then((data) => data.json());
}

export async function reserveBooking(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "reserve_booking/list", requestOptions).then(
    (data) => data.json()
  );
}

export async function sendPasswordLink(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: object,
  };
  return await fetch(
    baseUrl + "customer/send-link-to-mail",
    requestOptions
  ).then((data) => data.json());
}
export async function resetPassword(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "customer/resetPassword", requestOptions).then(
    (data) => data.json()
  );
}

export async function updateProfile(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "customer/updateProfile", requestOptions).then(
    (data) => data.json()
  );
}

export async function reserveBookingSave(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "reserve_booking/store", requestOptions).then(
    (data) => data.json()
  );
}

export async function changePassword(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "customer/changePassword", requestOptions).then(
    (data) => data.json()
  );
}
export async function credentialRequest(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "user/request-credential", requestOptions).then(
    (data) => data.json()
  );
}

export async function recommendedRestaurnt(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "restaurant/recommended", requestOptions).then(
    (data) => data.json()
  );
}

export async function rating(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "restaurant/rating", requestOptions).then(
    (data) => data.json()
  );
}

export async function favRestaurant(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "customer/fav-restaurant", requestOptions).then(
    (data) => data.json()
  );
}
export async function alergies(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "alergies/list", requestOptions).then((data) =>
    data.json()
  );
}

export async function getUserVoucher(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "user_voucher/list", requestOptions).then(
    (data) => data.json()
  );
}

export async function orderDetail(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
    method: "POST",
    body: object,
  };
  return await fetch(baseUrl + "user/orderDetail", requestOptions).then(
    (data) => data.json()
  );
}
