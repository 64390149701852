<template>
  <div class="custom-container py-5">
    <div class="row">
      <div class="col-12">
        <p>
          {{ $t("address_page.section_1_1") }}: Herr Lecker<br />
          {{ $t("address_page.section_1_2") }}: Herr Lecker (UG) <br />
          {{ $t("address_page.section_1_3") }}: Dr. Khalid Iqbal <br />
          Anschrift: Bahnhof Str.2, 85609 Dornach/Aschheim – Deutschland<br />
          {{ $t("form_phone") }}: (0) 8994387722 <br />
          {{ $t("fax") }}: (0) 8994387722 <br />
          {{ $t("booking_email") }}: info@tajdornach.de <br />
        </p>

        <p>
          IBAN <br />
          {{ $t("booking_email") }}: info@tajdornach.de <br />
          {{ $t("address_page.section_2_1") }}: <br />
          {{ $t("address_page.section_2_2") }}: <br />
          {{ $t("address_page.section_2_3") }}: <br />
          {{ $t("address_page.section_2_4") }}: <br />
          {{ $t("address_page.section_2_5") }}: <br />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Colophan",
};
</script>

<style>
.custom-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
}

.custom-container h1 {
  font-size: 28px;
  font-weight: 400;
  color: #666;
  margin-bottom: 10px;
}

.custom-container h2 {
  font-size: 21px;
  font-weight: 400;
  color: #666;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  line-height: 32px;
  padding-top: 20px;
}

.custom-container p {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #666;
  margin-bottom: 15px;
}

.custom-container p strong {
  font-weight: 700;
}
</style>
