<template>
  <div class="navbar">
    <router-link to="/" class="nav-logo">
      <img :src="'/images/logo_green.png'" alt="" />
    </router-link>
    <button
      class="navigation-search"
      v-b-modal.address-search
      v-if="this.$store.state.zipCode"
    >
      {{
        this.$store.state.zipCodeSearch
          ? this.$store.state.zipCodeSearch
          : "Search with zipcode"
      }}
    </button>

    <b-modal
      ref="zipcode-modal"
      id="address-search"
      size="md"
      centered
      title="Enter Zipcode"
    >
      <div
        class="text-center search-input mb-3"
        style="max-width: 100%; border: 1px solid #d7d7d7"
      >
        <b-input-group>
          <b-input-group-prepend is-text> </b-input-group-prepend>
          <b-form-input
            type="search"
            v-on:input="process"
            v-model="zipcode"
            placeholder="Zipcode, i.e 12345"
          ></b-form-input>
        </b-input-group>
      </div>
    </b-modal>
    <div>
      <div class="d-flex align-items-center">
        <b-dropdown right no-caret class="globeIcon">
          <template v-slot:button-content>
            <i class="fa fa-globe"></i>
          </template>
          <b-dropdown-item @click="handleChange('en')" href="#"
            >English</b-dropdown-item
          >
          <b-dropdown-item @click="handleChange('de')" href="#"
            >German</b-dropdown-item
          >
        </b-dropdown>
        <i class="fa fa-bars" v-b-modal.my-account style="font-size: 24px"></i>
      </div>

      <MyAccount />
      <Login />
      <CreateAccount />
      <ForgetPassword />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MyAccount from "../components/Modals/MyAccount";
import Login from "../components/Modals/Login";
import CreateAccount from "../components/Modals/CreateAccount";
import ForgetPassword from "../components/Modals/ForgetPassword";
import { apiUrl } from "../api/services";

export default {
  name: "Navbar",
  data() {
    return {
      zipcode: "",
    };
  },
  components: {
    MyAccount,
    Login,
    CreateAccount,
    ForgetPassword,
  },

  mounted() {
    this.handleChange("de");
  },

  methods: {
    handleChange(event) {
      var locale = event;
      import(`../langs/${locale}.json`).then((msgs) => {
        console.log(msgs);
        this.$i18n.setLocaleMessage(locale, msgs);
        this.$i18n.locale = locale;
      });
    },
    process: function () {
      if (this.zipcode.length > 4) {
        this.$refs["zipcode-modal"].hide();
        this.saerchResturant();
      }
    },
    saerchResturant: async function () {
      this.$store.commit("setsearchText", "");
      try {
        const data = {
          sort_by: "",
          per_page: "10",
          page: "1",
          postcode: this.zipcode,
          category_id: "",
          type: this.$store.state.type,
        };
        if (this.zipcode.length > 4) {
          const requestOne = axios.post(
            apiUrl + "customer/zipbase-restaurant",
            data
          );
          const requestTwo = axios.post(
            apiUrl + "customer/zip-base-category",
            data
          );

          await axios.all([requestOne, requestTwo]).then(
            await axios.spread((...responses) => {
              const responseOne = responses[0];
              const responseTwo = responses[1];

              if (responseOne.data.code == 200) {
                this.$store.commit("setZipCodeSearch", this.zipcode);
                this.$store.commit(
                  "setSearchResturants",
                  responseOne.data.data
                );
                this.$store.commit(
                  "setDummySearchResturants",
                  responseOne.data.data
                );
                this.$store.commit(
                  "setResturantsCategories",
                  responseTwo.data.data
                );
                console.log(responseTwo.data.data);
              } else {
                console.log("response.data.message", responseOne.data.message);
              }
            })
          );
        }
      } catch (err) {
        if (!err.response) {
          console.log(err);
          alert("Please Check You Internet Connection");
          this.buttonloader = false;
        }
      }
    },
  },
};
</script>
