<template>
    <div>
        <b-modal ref="hide_model" :no-close-on-backdrop="true" id="login" size="lg" centered ok-only no-stacking>
            <template #modal-header>
                <h5 class="modal-title">Sign in</h5>
                <button @click="hideModal()" type="button" class="close">
                    ×
                </button>
            </template>
            <GoogleLogin class="google-btn" :params="params" :onSuccess="onGmailSuccess" :onFailure="onFailure"><i
                    class="fab fa-google pr-3"></i> {{$t('sign_in_with_google')}}</GoogleLogin>

            <facebook-login :logoutLabel="$t('sign_in_with_facebook')" :loginLabel="$t('sign_up_with_facebook')" class="google-btn facebook-btn" @sdk-loaded="sdkLoaded"
                app-id="756691688970265" @login="getUserData" @logout="onLogout">
            </facebook-login>

            <div class="line">
                <h2><span>or</span></h2>
            </div>
            <b-form v-on:submit.prevent="onSubmit" ref="form_id">
                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul class="ErrorClass">
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>

                <div v-if="alert" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">
                    {{ alertText }}
                </div>
                <b-form-group id="input-group-1" :label="$t('form_email')">
                    <b-form-input type="email" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"   required="" v-model="email"></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-1">
                    <div class="d-flex align-items-center justify-content-between">
                        <label class="bv-no-focus-ring col-form-label pt-0">{{$t('form_password')}}</label>
                    <div class="forgot-password"><a v-b-modal.forget-password>{{$t('forget_password')}}?</a></div>
                    </div>
                    <b-form-input type="password" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"   required="" v-model="password"></b-form-input>
                </b-form-group>
                <button class="google-btn facebook-btn login-btn"> <i style="color:rgb(123 217 182)"
                        class="fa fa-spinner fa-spin " v-if="buttonloader"></i>{{$t('login')}}
                </button>
            </b-form>
            <div class="create-account"><a v-b-modal.create-account>{{$t('create_account')}}</a></div>
        </b-modal>
    </div>
</template>
<script>
    import GoogleLogin from 'vue-google-login';
    import facebookLogin from 'facebook-login-vuejs';
    import { apiUrl } from "../../api/services";

    import axios from "axios";
    import {
        socialLogin
    } from "../../api/services";

    export default {
        name: "Login",
        data() {
            return {
                params: {
                    client_id: "1062773302676-9jeoib8jsjnugtlcp65h457epkbhhvt0.apps.googleusercontent.com"
                },
                FB: undefined,
                buttonloader: false,    
                isConnected: false,
                alert: false,
                alertText: '',
                errors: [],
                isError: false,
                email: "",
                password: "",
            };
        },
        components: {
            GoogleLogin,
            facebookLogin
        },
        mounted() {
            // localStorage.removeItem("currentUser");
            // localStorage.removeItem("editRole");
            // localStorage.removeItem("token");
            // localStorage.removeItem("permissions");
            // localStorage.setItem("isAuthenticated", false);
            // this.$store.commit("isLogin", false);
        },

        methods: {

            sdkLoaded(payload) {
                this.isConnected = payload.isConnected
                this.FB = payload.FB
                if (this.isConnected) this.getUserData()
            },

            onLogin() {
                this.isConnected = true
                this.getUserData()
            },
            hideModal() {
                this.alert=false
                this.alertText=""
                this.$refs['hide_model'].hide()
            },
            onLogout() {
                console.log("this.isConnected = false;")
                this.isConnected = false;
            },

            getUserData(data) {
                let token = data.response.authResponse.accessToken;
                axios.get(`https://graph.facebook.com/v8.0/me?access_token=${token}`)
                    .then(response => {
                        let apiData = JSON.stringify({
                        token: response.data.id,
                        provider_type: 'facebook',
                        email: (response.data.email) ? response.data.email : "",
                        platform: "web"
                    });
                    socialLogin(apiData).then((responce) => {
                        if (responce.code == 200) {
                            this.successLogin(responce);
                        } else {
                            this.alertText = responce.message;
                            this.alert = true;
                            this.buttonloader = false;
                        }
                    });
                    });
               
            },

            successLogin(responce) {
                this.$store.commit("updateLoginUser", responce.data);
                localStorage.setItem("isAuthenticated", true);
                this.$store.commit("isLogin", true);
                localStorage.setItem("currentUser", JSON.stringify(responce.data));
                localStorage.setItem("token", JSON.stringify(responce.data.token));
                this.$bvModal.hide('login');
                this.$refs['hide_model'].hide();
            },

            onGmailSuccess(data) {
    
                let apiData = JSON.stringify({
                    token: data.getBasicProfile().getId(),
                    provider_type: 'google',
                    email: data.getBasicProfile().getEmail(),

                });
                socialLogin(apiData).then((responce) => {
                    if (responce.code == 200) {
                        this.successLogin(responce);

                    } else {
                        this.alertText = responce.message;
                        this.alert = true;
                        this.buttonloader = false;
                    }

                });
            },
            onFailure(data) {
                console.log("==Error=>", data)
            },
            onSubmit: async function () {
                try {
                    this.buttonloader = true;
                    this.alert = false;
                    this.errors = [];
                    this.isError = false;
                    const data = {
                        email: this.email,
                        password: this.password,
                    };

                    if (this.email.length > 0 && this.password.length > 0) {
                        await axios.post(apiUrl + "customer/login", data)
                            .then((response) => {
                                this.buttonloader = false;
                                if (response.data.code == 200) {
                                    this.successLogin(response.data);
                                    return
                                } else {
                                    console.log('response.data.message', response.data.message)
                                    this.alertText = response.data.message;
                                    this.alert = true;
                                    this.buttonloader = false;
                                    this.password = "";
                                }

                            });
                    } else {
                        if (this.email.length == 0) {
                            console.log('Her error');
                            this.errors.push('Email Field is required.*');
                            this.isError = true;
                        }

                        if (this.password.length == 0) {
                            this.errors.push('Password Field is required.*');
                            this.isError = true;
                        }
                        this.buttonloader = false;
                    }
                } catch (err) {
                    if (!err.response) {
                        alert("Please Check You Internet Connection");
                        this.buttonloader = false;
                    }
                }


            }
        }
    };
</script>